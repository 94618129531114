import { Typography } from '@mui/material'
import { ChevronRightMinor } from '@shopify/polaris-icons'
import { memo, useCallback } from 'react'
import { ActivityItem } from 'src/interfaces/activities'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import dayjs from 'dayjs'

interface Props {
  item: ActivityItem
  onClick(item: ActivityItem): void
}

const ActivitiesListItemComponent = ({ item, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick(item)
  }, [item, onClick])

  return (
    <div
      css={tw`p-3 flex border-0 border-b border-solid border-[#EDEDED] cursor-pointer hover:bg-[#F6F6F6] transition`}
      onClick={handleClick}
    >
      <div css={tw`flex-1`}>
        <div css={tw`flex justify-between items-center mb-2`}>
          <Typography variant="h6" fontWeight={600} color={colors.SHADES_GREY_400}>
            {item.action}
          </Typography>
          <Typography variant="subtitle2" fontWeight={500} color={colors.SHADES_GREY_300}>
            {dayjs(item.date).format('HH:mm MM/DD/YY')}
          </Typography>
        </div>
        <div>
          <Typography variant="body2" color={colors.BASICS_BLACK}>
            {item.title}
          </Typography>
        </div>
      </div>
      <div css={tw`ml-3 flex items-center`}>
        <ChevronRightMinor fill={colors.SHADES_GREY_200} width={20} />
      </div>
    </div>
  )
}

export const ActivitiesListItem = memo(ActivitiesListItemComponent)
