import { createTheme } from '@mui/material/styles'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'

export const colors = {
  // Brand
  BRAND_BLUE: '#7F00FF',
  BRAND_GREEN: '#51F7D9',
  BRAND_YELLOW: '#FFD442',
  BRAND_NAVY: '#181829',

  // Grayscale
  GRAY_BLACK: '#000000',
  GRAY_DARK_COOL: '#655E7C',
  GRAY_COOL_GRAY: '#847E96',
  GRAY_MID_COOL: '#DAD9E0',
  GRAY_LIGHT_COOL: '#F6F5F7',
  GRAY_PALE_COOL: '#FAFAFB',
  GRAY_WHITE: '#FFFFFF',

  // Form
  //
  ERROR: '#E33914',
  SUCCESS: '#04C523',

  BASICS_BLACK: 'rgba(0, 0, 0, 1)',
  BASICS_GREY: 'rgba(96, 92, 98, 1)',
  BASICS_WHITE: 'rgba(255, 255, 255, 1)',

  COLORS_BLUE_200: 'rgba(216, 229, 255, 1)',
  COLORS_BLUE_500: 'rgba(0, 86, 255, 1)',

  COLORS_GREEN_200: 'rgba(230, 249, 233, 1)',
  COLORS_GREEN_500: 'rgba(3, 182, 32, 1)',

  COLORS_ORANGE_200: 'rgba(255, 238, 214, 1)',
  COLORS_ORANGE_500: 'rgba(255, 99, 32, 1)',

  COLORS_PINK_200: 'rgba(255, 210, 248, 1)',
  COLORS_PINK_500: 'rgba(215, 0, 181, 1)',

  COLORS_RED_200: 'rgba(255, 232, 238, 1)',
  COLORS_RED_500: 'rgba(255, 26, 81, 1)',

  COLORS_YELLOW_200: 'rgba(255, 242, 197, 1)',
  COLORS_YELLOW_500: 'rgba(255, 199, 0, 1)',

  PRIMARY_PURPLE_100: 'rgba(242, 229, 255, 1)',
  PRIMARY_PURPLE_200: 'rgba(226, 208, 244, 1)',
  PRIMARY_PURPLE_500: 'rgba(127, 0, 255, 1)',
  PRIMARY_PURPLE_400: '#A64DFF',
  PRIMARY_PURPLE_700: 'rgba(68, 11, 124, 1)',
  PRIMARY_PURPLE_800: 'rgba(48, 2, 88, 1)',

  SHADES_GREY_100: 'rgba(237, 237, 237, 1)',
  SHADES_GREY_200: 'rgba(218, 217, 224, 1)',
  SHADES_GREY_300: 'rgba(180, 175, 192, 1)',
  SHADES_GREY_400: 'rgba(132, 126, 150, 1)',
  SHADES_GREY_50: 'rgba(246, 246, 246, 1)',
  SHADES_GREY_500: 'rgba(101, 94, 124, 1)',
}

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.PRIMARY_PURPLE_500,
    },
    secondary: {
      main: colors.BRAND_NAVY,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'black', // Change this to your desired background color
          color: 'white', // Optional: Change text color
          borderRadius: 8,
          fontFamily: 'SF Pro Display, Helvetica',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          letterSpacing: '0px',
          lineHeight: '20px',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 12,
          paddingBottom: 12,
        },
        arrow: {
          color: 'black', // Change this to match the background color for the arrow
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-columnSeparator': {
            color: '#655E7C',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontFamily: 'SF Pro Display',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '20px',
            color: '#655E7C',
          },
          '& .MuiDataGrid-cell': {
            '&:focus': {
              outline: 'none',
            },
            cursor: 'pointer',
            fontFamily: 'SF Pro Display',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            color: '#655E7C',
            paddingLeft: '16px',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-cellCheckbox': {
            paddingLeft: '0px',
          },
          '& .MuiDataGrid-columnHeader': {
            '&:focus': {
              outline: 'none',
            },
            paddingLeft: '16px',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            paddingLeft: '0px',
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.07)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '56px',
          borderRadius: '8px',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { borderRadius: '8px', height: 36 },
        input: {
          fontFamily: 'SF Pro Display, Helvetica',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          letterSpacing: '0px',
          lineHeight: '20px',
        },
        multiline: {
          height: 'auto',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { borderRadius: '8px' },
        multiline: {
          padding: 8,
          paddingLeft: 12,
          paddingRight: 12,
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'SF Pro Display, Helvetica',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          letterSpacing: '0px',
          lineHeight: '20px',
        },
      },
    },
  },
  typography: {
    body1: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0px',
      lineHeight: '22px',
    },
    body2: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0px',
      lineHeight: '20px',
    },
    button: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      letterSpacing: '0.32px',
      lineHeight: '20px',
    },
    caption: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: '22px',
    },
    h1: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '34px',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '-0.68px',
      lineHeight: '42px',
    },
    h2: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '-0.56px',
      lineHeight: '32px',
    },
    h3: {
      fontFamily: 'SF Pro Display',
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '-0.44px',
      lineHeight: '32px',
    },
    h4: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '0px',
      lineHeight: '22px',
    },
    h5: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '0px',
      lineHeight: '20px',
    },
    h6: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      letterSpacing: '0px',
      lineHeight: '20px',
    },
    // inherit: {
    //   fontFamily: 'SF Pro Display, Helvetica',
    //   fontSize: '16px',
    //   fontStyle: 'normal',
    //   fontWeight: 500,
    //   letterSpacing: '0px',
    //   lineHeight: '22px',
    // },
    overline: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '0.24px',
      lineHeight: '16px',
    },
    subtitle1: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
    },
    subtitle2: {
      fontFamily: 'SF Pro Display, Helvetica',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: '16px',
    },
  },
})
