import { memo } from 'react'
import { ContractorDetails } from 'src/interfaces/contractor'
import { ContractorStepItem } from './ContractorStepItem'
import { CollectedDataStatus } from 'src/constants/collectedData'

interface Props {
  contractorDetails: ContractorDetails
  filterByStatus?: CollectedDataStatus
  collapsible?: boolean
  onRefresh: () => void
}

const ContractorStepsComponent = ({
  contractorDetails,
  filterByStatus,
  collapsible,
  onRefresh,
}: Props) => {
  return (
    <div>
      {contractorDetails.collectedData
        .filter((item) => !filterByStatus || item.status === filterByStatus)
        .map((item) => (
          <ContractorStepItem
            collectedData={item}
            key={item.id}
            contractorDetails={contractorDetails}
            onRefreshContractors={onRefresh}
            collapsible={collapsible}
          />
        ))}
    </div>
  )
}

export const ContractorSteps = memo(ContractorStepsComponent)
