import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { ContractorsGrid } from './ContractorsGrid'
import { useDispatch, useSelector } from 'react-redux'
import { Contractor, ContractorDetails } from 'src/interfaces/contractor'
import { createAsyncAction } from 'src/utils/reduxUtils'
import {
  FetchContractor,
  FetchContractors,
  FetchContractorsCounts,
} from 'src/constants/actionTypes'
import {
  contractorPageSizeOptions,
  contractorInitialSortFields,
} from 'src/constants/contractor/contractorGrid'
import { IContractorsContext, ContractorsContext } from './Contractors.context'
import { useGridPagination } from 'src/utils/hooks/grid/useGridPagination'
import { getSelectedMarket } from 'src/selectors/market'
import { ContractorsHeader } from './ContractorsHeader'
import { Typography } from '@mui/material'
import { ContractorState, ContractorStatus } from 'src/constants/contractor/enums'
import { ManageTagsButton } from 'src/components/tags/ManageTagsButton'
import { ContractorTabs } from '../tabs/ContractorTabs'
import { ContractorDetailsModal } from 'src/components/contractor-details/ContractorDetailsModal'
import { handleApiError } from 'src/utils/errorHandlers'

interface FetchContractorsParams {
  limit?: number
  offset?: number
  searchQuery?: string
  tagsFilter?: Array<string>
}

const ContractorsComponent = () => {
  const dispatch = useDispatch()

  const selectedMarket = useSelector(getSelectedMarket)

  const { paginationModel, limit, offset } = useGridPagination(contractorPageSizeOptions)

  const [selectedTab, setSelectedTab] = useState(ContractorState.Active)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [contractors, setContractors] = useState<Array<Contractor>>([])
  const [totalContractors, setTotalContractors] = useState<number>(0)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState<Array<ContractorStatus>>([])
  const [tagsFilter, setTagsFilter] = useState<Array<string>>([])
  const [selectedContractorId, setSelectedContractorId] = useState<string | null>(null)
  const [isManageTagsOpen, setIsManageTagsOpen] = useState(false)
  const [contractorDetails, setContractorDetails] = useState<ContractorDetails | null>(null)

  const fetchContractors = useCallback(
    async ({ limit, offset, searchQuery }: FetchContractorsParams) => {
      setIsLoading(true)

      try {
        const params: any = {
          limit,
          offset,
          contractorState: selectedTab,
          contractorStatus: statusFilter,
        }

        if (searchQuery) {
          params.contractorSearch = searchQuery
        }

        if (selectedMarket?.id) {
          params.partnerMarketId = selectedMarket?.id
        }

        if (tagsFilter?.length) {
          params.tags = tagsFilter
        }

        const payload: Array<Contractor> = await createAsyncAction(
          dispatch,
          FetchContractors.request(params),
        )

        setContractors(payload)
        setTotalContractors(payload.length)
      } catch (err: unknown) {
        handleApiError(err)
      }

      setIsLoading(false)
    },
    [selectedTab, statusFilter, tagsFilter, selectedMarket?.id],
  )

  const fetchContractorsCounts = useCallback(() => {
    const params: any = {}

    if (selectedMarket?.id) {
      params.partnerMarketId = selectedMarket?.id
    }

    dispatch(FetchContractorsCounts.request(params))
  }, [selectedMarket?.id])

  const refreshContractors = useCallback(async () => {
    await fetchContractors({ limit, offset, searchQuery })
    fetchContractorsCounts()
  }, [limit, offset, searchQuery, fetchContractors, fetchContractorsCounts])

  useEffect(() => {
    fetchContractors({
      limit,
      offset,
      searchQuery,
    })
    fetchContractorsCounts()
  }, [
    limit,
    offset,
    searchQuery,
    selectedTab,
    tagsFilter,
    statusFilter,
    selectedMarket?.id,
    fetchContractorsCounts,
  ])

  const handleRowClick = useCallback(({ row }: { row: Contractor }) => {
    setSelectedContractorId(row.id)
  }, [])

  const handleCloseDetails = useCallback(() => {
    setSelectedContractorId(null)
  }, [])

  const openManageTags = useCallback(() => {
    setIsManageTagsOpen(true)
  }, [])

  const closeManageTags = useCallback(() => {
    setIsManageTagsOpen(false)
  }, [])

  const contractorsContextValue = useMemo<IContractorsContext>(() => {
    return {
      searchQuery,
      setSearchQuery,

      statusFilter,
      setStatusFilter,

      tagsFilter,
      setTagsFilter,

      refreshContractors,

      openManageTags,
      closeManageTags,
    }
  }, [
    searchQuery,
    setSearchQuery,

    statusFilter,
    setStatusFilter,

    tagsFilter,
    setTagsFilter,

    refreshContractors,

    openManageTags,
    closeManageTags,
  ])

  const fetchContractorDetails = useCallback(
    async (contractorId: string) => {
      try {
        const contractor: ContractorDetails = await createAsyncAction(
          dispatch,
          FetchContractor.request({ contractorId, contractorState: selectedTab }),
        )
        setContractorDetails(contractor)
      } catch (err) {
        handleApiError(err)
      }
    },
    [selectedTab],
  )

  useEffect(() => {
    if (!selectedContractorId) {
      setContractorDetails(null)
    } else {
      fetchContractorDetails(selectedContractorId)
    }
  }, [selectedContractorId])

  const refreshContractorDetails = useCallback(async () => {
    if (selectedContractorId) {
      await fetchContractorDetails(selectedContractorId)
    }
    refreshContractors()
  }, [selectedContractorId, refreshContractors])

  const handleChangeTab = useCallback((tab: ContractorState) => {
    setSelectedTab(tab)
    setStatusFilter([])
  }, [])

  return (
    <ContractorsContext.Provider value={contractorsContextValue}>
      <div css={tw`flex flex-row items-center justify-between mb-6`}>
        <Typography variant="h2" css={tw`mb-2`}>
          Contractors
        </Typography>
        <div>
          <ManageTagsButton
            isManageTagsOpen={isManageTagsOpen}
            openManageTags={openManageTags}
            closeManageTags={closeManageTags}
          />
        </div>
      </div>
      <ContractorTabs selectedTab={selectedTab} onClick={handleChangeTab} />
      <ContractorsHeader selectedContractorState={selectedTab} />
      <ContractorsGrid
        contractors={contractors}
        isLoading={isLoading}
        selectedContractorState={selectedTab}
        paginationModel={paginationModel}
        pageSizeOptions={contractorPageSizeOptions}
        rowCount={totalContractors}
        initialSortField={contractorInitialSortFields[selectedTab]}
        onRowClick={handleRowClick}
      />
      {contractorDetails && (
        <ContractorDetailsModal
          contractorDetails={contractorDetails}
          showWaitingForApprovalStepsFirst={selectedTab === ContractorState.Recollection}
          flow="contractors"
          onClose={handleCloseDetails}
          onRefresh={refreshContractorDetails}
        />
      )}
    </ContractorsContext.Provider>
  )
}

export const Contractors = memo(ContractorsComponent)
