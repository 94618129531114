import { put, takeEvery, call } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  FetchPayments,
  UploadPaymentsCSV,
  FetchPaymentInvoice,
  DeletePayment,
  ProcessPayments,
} from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { ApiService, callSecureApi } from './api/callApi'
import { Payment } from 'src/interfaces/payment'

export interface FetchPaymentsPayload {
  partnerMarketId?: string
  search?: string
}

function* onFetchPayments(action: AsyncAction<FetchPaymentsPayload, Array<Payment>>) {
  const { partnerMarketId, search } = action.payload

  try {
    const { payload }: { payload: Array<Payment> } = yield call(
      callSecureApi,
      Endpoints.FetchPayments,
      {
        apiService: ApiService.Paraworks,
        query: {
          partnerMarketId,
          search,
          limit: 10000,
          // offset,
        },
      },
    )

    yield put(FetchPayments.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch payments'

    yield put(FetchPayments.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadPaymentsCSVRequest {
  file: File
  partnerId: string
}

function* onUploadPaymentsCSV(action: AsyncAction<UploadPaymentsCSVRequest>) {
  const { file } = action.payload

  try {
    const formData = new FormData()

    formData.append('file', file)

    yield call(callSecureApi, Endpoints.UploadPaymentsCSV, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      // returnResponse: true,
    })

    yield put(UploadPaymentsCSV.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload payments csv'

    yield put(UploadPaymentsCSV.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export interface FetchPaymentInvoicePayload {
  paymentId: string
}

function* onFetchPaymentInvoice(action: AsyncAction<FetchPaymentInvoicePayload, Array<Payment>>) {
  const { paymentId } = action.payload

  try {
    const { payload }: { payload: Array<Payment> } = yield call(
      callSecureApi,
      Endpoints.FetchPaymentInvoice,
      {
        apiService: ApiService.Paraworks,
        params: [
          {
            value: paymentId,
            field: ':paymentId',
          },
        ],
        returnBuffer: true,
      },
    )

    yield put(FetchPaymentInvoice.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch payments'

    yield put(FetchPaymentInvoice.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export interface DeletePaymentPayload {
  paymentIds: Array<string>
}

function* onDeletePayment(action: AsyncAction<DeletePaymentPayload, Array<Payment>>) {
  const { paymentIds } = action.payload

  try {
    const { payload }: { payload: Array<Payment> } = yield call(
      callSecureApi,
      Endpoints.DeletePayment,
      {
        method: 'DELETE',
        apiService: ApiService.Paraworks,
        body: {
          earningIds: paymentIds,
        },
      },
    )

    yield put(DeletePayment.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = 'Failed to delete payment'

    yield put(DeletePayment.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onProcessPayments(action: AsyncAction<void, Array<Payment>>) {
  try {
    const { payload }: { payload: Array<Payment> } = yield call(
      callSecureApi,
      Endpoints.ProcessPayments,
      {
        method: 'POST',
        apiService: ApiService.Paraworks,
      },
    )

    yield put(ProcessPayments.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = 'Failed to process payments'

    yield put(ProcessPayments.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* paymentSaga() {
  yield takeEvery(FetchPayments.type.REQUEST, onFetchPayments)
  yield takeEvery(UploadPaymentsCSV.type.REQUEST, onUploadPaymentsCSV)
  yield takeEvery(FetchPaymentInvoice.type.REQUEST, onFetchPaymentInvoice)
  yield takeEvery(DeletePayment.type.REQUEST, onDeletePayment)
  yield takeEvery(ProcessPayments.type.REQUEST, onProcessPayments)
}
