import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { gridFormatters, gridValueGetters } from 'src/utils/grid'
import { Contractor } from 'src/interfaces/contractor'
import { ContractorStatusChip } from 'src/components/contractor/grid/ContractorStatusChip'
import { TableTags } from 'src/components/tags/TableTags'
import { ContractorState, ContractorStatus } from './enums'

const sharedContractorColumns: Array<GridColDef> = [
  {
    field: 'externalId',
    headerName: 'Contractors ID',
    flex: 2,
    sortable: true,
  },
  {
    field: 'name',
    headerName: 'Contractors name',
    flex: 2,
    sortable: true,
  },
]

const statusCol = {
  field: 'status',
  headerName: 'Status',
  flex: 2,
  sortable: false,
  renderCell: (params: GridRenderCellParams<Contractor>) => {
    if (params.row?.expiringSoonDocuments) {
      return <ContractorStatusChip status={ContractorStatus.ExpiresSoon} />
    }

    return (
      <ContractorStatusChip
        status={params.row?.isActive ? ContractorStatus.Active : ContractorStatus.Deactivated}
      />
    )
  },
}

const tagsCol = {
  field: 'tags',
  headerName: 'Tag(s)',
  flex: 3,
  sortable: false,
  renderCell: (params: GridRenderCellParams<Contractor>) =>
    params.row?.tags.filter((item) => !!item).length ? (
      <TableTags tags={params.row?.tags.filter((item) => !!item) || []} />
    ) : (
      <span>-</span>
    ),
}

export const contractorColumns = {
  [ContractorState.Active]: [
    ...sharedContractorColumns,
    statusCol,
    tagsCol,
    {
      field: 'onboardedAt',
      headerName: 'Date onboarded',
      flex: 2,
      sortable: true,
      valueGetter: gridValueGetters.parseDate,
      valueFormatter: gridFormatters.formatShortDate,
    },
  ],
  [ContractorState.Deactivated]: [
    ...sharedContractorColumns,
    tagsCol,
    {
      field: 'deactivatedAt',
      headerName: 'Date deactivated',
      flex: 2,
      sortable: true,
      valueGetter: gridValueGetters.parseDate,
      valueFormatter: gridFormatters.formatShortDate,
    },
  ],
  [ContractorState.Recollection]: [...sharedContractorColumns, statusCol, tagsCol],
}

export const contractorInitialSortFields = {
  [ContractorState.Active]: 'onboardedAt',
  [ContractorState.Deactivated]: 'deactivatedAt',
  [ContractorState.Recollection]: undefined,
}

export const contractorPageSizeOptions = [5, 10, 20, 50, 100]

export const ONBOARDINGS_COLUMNS_KEY = 'contractorsColumns'
