import { fork } from 'redux-saga/effects'

import { authSaga } from './auth'
import { userSaga } from './user'
import { inviteSaga } from './invite'
import { marketSaga } from './market'
import { tagSaga } from './tag'
import { onboardingSaga } from './onboarding'
import { paymentSaga } from './payment'
import { contractorSaga } from './contractor'

export function* rootSaga() {
  yield fork(authSaga)
  yield fork(userSaga)
  yield fork(inviteSaga)
  yield fork(tagSaga)
  yield fork(marketSaga)
  yield fork(contractorSaga)
  yield fork(paymentSaga)
  yield fork(onboardingSaga)
}
