import React, { Fragment, useCallback, useMemo } from 'react'
import tw, { css } from 'twin.macro'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { BalanceMajor, ChecklistAlternateMajor, CustomersMajor } from '@shopify/polaris-icons'
import { SideMenuButton } from '../shared/SideMenuButton'
import { useSelector } from 'react-redux'
import { getAccessInfo } from 'src/selectors/user'
import { UserPermission, UserProductName } from 'src/constants/user'

export const SideMenu = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const accessInfo = useSelector(getAccessInfo)

  const handleNavigate = useCallback((route: string) => {
    navigate(route)
  }, [])

  const buttons = useMemo(() => {
    const res: any = [
      {
        route: ROUTES.CONTRACTORS_URL,
        label: 'Contractors',
        icon: <CustomersMajor height={20} width={20} />,
      },
      {
        route: ROUTES.ONBOARDING_URL,
        label: 'Onboarding',
        icon: <ChecklistAlternateMajor height={20} width={20} />,
      },
    ]

    if (
      accessInfo?.permission?.includes(UserPermission.PaymentsView) &&
      accessInfo?.products?.some((item) => item.product === UserProductName.Payments)
    ) {
      res.push({
        route: ROUTES.PAYMENTS_URL,
        label: 'Payments',
        icon: <BalanceMajor height={20} width={20} />,
      })
    }

    return res
  }, [accessInfo])

  return (
    <div css={tw`w-[204px] bg-[#EDEDED] flex justify-between flex-col`}>
      <div css={tw`py-6 px-4`}>
        {buttons.map((item: any) => (
          <Fragment key={item.label}>
            <SideMenuButton
              key={item.route}
              icon={item.icon}
              label={item.label}
              route={item.route}
              className={css(tw`mb-2 w-full justify-start hover:bg-[#F6F6F6]`)}
              selected={item.route?.includes(location.pathname)}
              onClick={handleNavigate}
            />
          </Fragment>
        ))}
      </div>
    </div>
  )
}
