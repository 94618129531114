import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { store } from './reduxStore'
import { Provider } from 'react-redux'
import { memo } from 'react'
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AppRoutes } from './AppRoutes'
import { SnackbarErrorWithDetails } from './components/shared/SnackbarErrorWithDetails'

export const App = memo(() => {
  return (
    <Provider store={store}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            Components={{
              errorWithDetails: SnackbarErrorWithDetails,
            }}
          >
            <AppRoutes />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  )
})
