import { memo, useCallback, useEffect, useState } from 'react'
import { ActivityItem } from 'src/interfaces/activities'
import { ActivitiesListItem } from './ActivitiesListItem'
import { activitiesService } from 'src/services/ActivitiesService'
import tw from 'twin.macro'
import { SetActivityDetails } from 'src/constants/actionTypes'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'

interface Props {
  onItemClick(): void
}

const ActivitiesListComponent = ({ onItemClick }: Props) => {
  const dispatch = useDispatch()

  const [activities, setActivities] = useState<ActivityItem[]>([])

  useEffect(() => {
    setActivities(activitiesService.getActivities())
  }, [])

  const handleItemClick = useCallback(
    (item: ActivityItem) => {
      dispatch(SetActivityDetails.success(item))
      onItemClick()
    },
    [onItemClick],
  )

  if (!activities.length) {
    return (
      <div css={tw`p-4 w-[460px]`}>
        <Typography
          css={tw`text-center`}
          variant="body2"
          color={colors.SHADES_GREY_400}
          fontWeight={500}
        >
          No activities found
        </Typography>
      </div>
    )
  }

  return (
    <div css={tw`w-[460px]`}>
      {activities.map((activity) => (
        <ActivitiesListItem key={activity.id} item={activity} onClick={handleItemClick} />
      ))}
    </div>
  )
}

export const ActivitiesList = memo(ActivitiesListComponent)
