import React, { memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import { ActionButton } from 'src/components/shared/ActionButton'
import { ActionMenu } from 'src/components/shared/ActionMenu'
import { MenuActionItem } from 'src/interfaces/ui'
import { useDispatch } from 'react-redux'
import {
  ApproveOnboardingStep,
  RecollectOnboardingStep,
  RejectOnboarding,
} from 'src/constants/actionTypes'
import { ReplayMinor, ThumbsDownMinor, ThumbsUpMinor } from '@shopify/polaris-icons'
import { ContractorDetails } from 'src/interfaces/contractor'
import { ContractorOnboardingStatus } from 'src/constants/contractor/enums'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { showToast } from 'src/utils/toast'
import { SensitiveActionModal } from 'src/components/shared/SensitiveActionModal'
import { CollectedData } from 'src/interfaces/collectedData'
import { CollectedDataStatus, CollectedDataStepType } from 'src/constants/collectedData'
import { handleApiError } from 'src/utils/errorHandlers'
import { getCollectDataStepTitle } from 'src/utils/collectedData'

interface Props {
  collectedData: CollectedData
  contractorDetails: ContractorDetails
  recollecting?: boolean
  stepCanBeRecollected?: boolean
  onAction(): void
}

const ContractorStepActionsButtonComponent = ({
  collectedData,
  contractorDetails,
  recollecting,
  stepCanBeRecollected,
  onAction,
}: Props) => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isRecollectConfirmOpen, setIsRecollectConfirmOpen] = useState(false)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleOpenRecollectConfirm = useCallback(() => {
    setIsRecollectConfirmOpen(true)
  }, [])

  const handleCloseRecollectConfirm = useCallback(() => {
    setIsRecollectConfirmOpen(false)
  }, [])

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleRecollect = useCallback(async () => {
    handleCloseRecollectConfirm()

    setIsLoading(true)

    try {
      await createAsyncAction(
        dispatch,
        RecollectOnboardingStep.request({
          stepId: collectedData.collectDataStep.id,
          partnerDriverId: contractorDetails.id,
        }),
      )
      showToast('Step is sent to recollection.')
      onAction()
    } catch (err) {
      handleApiError(err)
    }

    setIsLoading(false)
  }, [collectedData, contractorDetails, onAction])

  const actionItems: Array<MenuActionItem> = useMemo(() => {
    const res = []

    const recollect = async () => {
      setAnchorEl(null)

      handleOpenRecollectConfirm()
    }

    const rejectContractor = async () => {
      try {
        await createAsyncAction(
          dispatch,
          RejectOnboarding.request({ onboardingId: contractorDetails.onboarding.id }),
        )
        showToast('Step is rejected.')
        onAction()
      } catch (err) {
        handleApiError(err)
      }
    }

    if (collectedData.status !== CollectedDataStatus.Completed) {
      res.push({
        label: 'Approve',
        icon: <ThumbsUpMinor width={20} />,
        onClick: async () => {
          setAnchorEl(null)

          setIsLoading(true)

          try {
            await createAsyncAction(
              dispatch,
              ApproveOnboardingStep.request({
                stepId: collectedData.collectDataStep.id,
                partnerDriverId: contractorDetails.id,
              }),
            )

            showToast('Step is approved.')
            onAction()
          } catch (err) {
            handleApiError(err)
          }

          setIsLoading(false)
        },
      })
    }

    if (!recollecting) {
      if (
        !contractorDetails.onboarding.status ||
        contractorDetails.onboarding.status === ContractorOnboardingStatus.Completed ||
        stepCanBeRecollected
      ) {
        res.push({
          label: 'Recollect',
          icon: <ReplayMinor width={20} />,
          onClick:
            collectedData.collectDataStep.type === CollectedDataStepType.InPersonMeeting
              ? rejectContractor
              : recollect,
        })
      } else {
        res.push({
          label: 'Decline',
          icon: <ThumbsDownMinor width={20} />,
          onClick:
            collectedData.collectDataStep.type === CollectedDataStepType.InPersonMeeting
              ? rejectContractor
              : recollect,
        })
      }
    }

    return res
  }, [collectedData, contractorDetails, recollecting, onAction])

  if (!actionItems.length) {
    return null
  }

  return (
    <>
      <ActionButton
        loading={isLoading}
        selected={!!anchorEl}
        color="grey"
        text="Actions"
        onClick={handleClick}
      />
      <ActionMenu
        anchorEl={anchorEl}
        items={actionItems}
        isOpen={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseMenu}
      />
      <SensitiveActionModal
        isOpen={isRecollectConfirmOpen}
        title="Recollect step?"
        text={`Are you sure you want to initiate ${getCollectDataStepTitle(
          collectedData,
        )} recollection?`}
        submitLabel="Yes"
        onClose={handleCloseRecollectConfirm}
        onSubmit={handleRecollect}
      />
    </>
  )
}

export const ContractorStepActionsButton = memo(ContractorStepActionsButtonComponent)
