import React, { memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { ActionButton } from '../shared/ActionButton'
import { ClockMinor } from '@shopify/polaris-icons'
import { Popover } from '@mui/material'
import { ActivitiesList } from '../activities/ActivitiesList'

const ActivitiesButtonComponent = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <ActionButton
        selected={!!anchorEl}
        className={tw`px-4 mr-4`}
        color="dark"
        text="Activities"
        icon={<ClockMinor fill="white" width={20} />}
        onClick={handleClick}
      />
      <Popover
        anchorEl={anchorEl}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseMenu}
      >
        {isMenuOpen ? <ActivitiesList onItemClick={handleCloseMenu} /> : null}
      </Popover>
    </>
  )
}

export const ActivitiesButton = memo(ActivitiesButtonComponent)
