import { Logout, SetActivityDetails } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { ActivitiesState } from './types'

const getInitialState = (): ActivitiesState => ({
  activityDetails: null,
})

export const activitiesReducer = (
  state: ActivitiesState = getInitialState(),
  action: Action<any>,
): ActivitiesState => {
  switch (action.type) {
    case SetActivityDetails.type.SUCCESS:
      return {
        ...state,
        activityDetails: action.payload,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
