import React, { memo, useCallback, useMemo, useState } from 'react'
import { ContractorDetails } from 'src/interfaces/contractor'
import { ContractorStepDocument } from './ContractorStepDocument'
import { ContractorStepActionsButton } from './ContractorStepActionsButton'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { ContractorStepStatus } from './ContractorStepStatus'
import { CollectedData } from 'src/interfaces/collectedData'
import {
  collectedDataStepIconsMap,
  CollectedDataStatus,
  CollectedDataStepType,
} from 'src/constants/collectedData'
import { getCollectDataStepTitle } from 'src/utils/collectedData'
import { ChevronDownMinor, ChevronUpMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'

interface Props {
  collectedData: CollectedData
  contractorDetails: ContractorDetails
  onRefreshContractors(): void
  collapsible?: boolean
}

const COLLECTED_DATA_STATUSES_WITHOUT_CONTENT = [
  CollectedDataStatus.Created,
  CollectedDataStatus.InProgress,
  CollectedDataStatus.WaitingForInternalApproval,
]

const COLLECTED_DATA_STEP_TYPES_WITHOUT_CONTENT = [
  CollectedDataStepType.StitchPayment,
  CollectedDataStepType.InPersonMeeting,
]

const ContractorStepItemComponent = ({
  collectedData,
  contractorDetails,
  onRefreshContractors,
  collapsible,
}: Props) => {
  const [collapsed, setCollapsed] = useState(collapsible ?? false)

  const stepCanBeRecollected = useMemo(
    () =>
      contractorDetails.collectDataTemplate?.collectDataTemplateSteps?.some(
        (template) =>
          template.label === collectedData.collectDataStep.label &&
          template.type === collectedData.collectDataStep.type,
      ),
    [collectedData],
  )

  const hasContent = useMemo(
    () =>
      !COLLECTED_DATA_STATUSES_WITHOUT_CONTENT.includes(collectedData.status) &&
      !COLLECTED_DATA_STEP_TYPES_WITHOUT_CONTENT.includes(collectedData.collectDataStep.type),
    [collectedData],
  )

  const toggleCollapse = useCallback(() => {
    if (!collapsible) {
      return
    }
    setCollapsed((prev) => !prev)
  }, [collapsible])

  const renderHeader = useCallback(
    (collapsible: boolean) => (
      <div
        css={tw`px-4 py-3 flex items-center justify-between`}
        onClick={collapsible && hasContent ? toggleCollapse : undefined}
      >
        <div css={tw`flex items-center`}>
          <div>{collectedDataStepIconsMap[collectedData.collectDataStep.type]}</div>
          <div css={tw`ml-3`}>
            <Typography variant="subtitle1">{getCollectDataStepTitle(collectedData)}</Typography>
            <ContractorStepStatus
              recollecting={collectedData.isRecollecting}
              status={collectedData.status}
            />
          </div>
        </div>
        {collectedData.status === CollectedDataStatus.WaitingForApproval || stepCanBeRecollected ? (
          <ContractorStepActionsButton
            contractorDetails={contractorDetails}
            collectedData={collectedData}
            recollecting={collectedData.isRecollecting}
            stepCanBeRecollected={stepCanBeRecollected}
            onAction={onRefreshContractors}
          />
        ) : null}
        {collapsible &&
          hasContent &&
          (collapsed ? (
            <ChevronDownMinor width={20} fill={colors.SHADES_GREY_300} />
          ) : (
            <ChevronUpMinor width={20} fill={colors.SHADES_GREY_300} />
          ))}
      </div>
    ),
    [collectedData, contractorDetails, collectedData, stepCanBeRecollected, collapsed, collapsible],
  )

  return (
    <div css={tw`bg-white rounded-lg mb-2`}>
      {renderHeader(collapsible ?? false)}
      <ContractorStepDocument
        header={renderHeader(false)}
        collectedData={collectedData}
        contractorDetails={contractorDetails}
        collapsed={collapsed}
      />
    </div>
  )
}

export const ContractorStepItem = memo(ContractorStepItemComponent)
