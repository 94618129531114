import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { PaymentsGrid } from './PaymentsGrid'
import { useDispatch, useSelector } from 'react-redux'
import { Payment } from 'src/interfaces/payment'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { FetchPayments } from 'src/constants/actionTypes'
import { paymentPageSizeOptions } from 'src/constants/payment/paymentGrid'
import { IPaymentsContext, PaymentsContext } from './Payments.context'
import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { useGridPagination } from 'src/utils/hooks/grid/useGridPagination'
import { getPartnerId } from 'src/selectors/user'
import { getSelectedMarket } from 'src/selectors/market'
import { PaymentsHeader } from './PaymentsHeader'
import { Typography } from '@mui/material'
import { PaymentsItemActionMenu } from './action/PaymentsItemActionMenu'
import { Button } from 'src/components/shared/Button'
import { NoteMinor } from '@shopify/polaris-icons'
import { UploadPaymentsCSVButton } from '../UploadPaymentsCSVButton'
import { PaymentStatus } from 'src/constants/payment/enums'
import { ProcessPaymentsButton } from '../ProcessPaymentsButton'
import { ProtectedWithPermission } from 'src/components/shared/ProtectedWithPermission'
import { UserPermission } from 'src/constants/user'
import { handleApiError } from 'src/utils/errorHandlers'

interface FetchPaymentsParams {
  limit?: number
  offset?: number
  searchQuery?: string
  tagsFilter?: Array<string>
}

const PaymentsComponent = () => {
  const dispatch = useDispatch()

  const partnerId = useSelector(getPartnerId)
  const selectedMarket = useSelector(getSelectedMarket)

  const { paginationModel, limit, offset, setPaginationModel } =
    useGridPagination(paymentPageSizeOptions)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [payments, setPayments] = useState<Array<Payment>>([])
  const [totalPayments, setTotalPayments] = useState<number>(0)
  const [actionAnchor, setActionAnchor] = useState<null | HTMLElement>(null)
  const [paymentToAction, setPaymentToAction] = useState<Payment | null>(null)
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState<Array<PaymentStatus>>([])

  const notPaidPayments = useMemo(
    () => payments.filter((item) => item.status === PaymentStatus.NotPaid),
    [payments],
  )

  const filteredPayments = useMemo(
    () =>
      statusFilter.length
        ? payments.filter((item) => statusFilter.includes(item.status))
        : payments,
    [payments, statusFilter],
  )

  const selectedPayments = useMemo<Array<Payment>>(() => {
    return selectedRows.reduce<Array<Payment>>((arr, selectedRowId) => {
      const payment = filteredPayments.find((item) => item.id === selectedRowId)

      if (payment) {
        arr.push(payment)
      }

      return arr
    }, [])
  }, [filteredPayments, selectedRows])

  const fetchPayments = useCallback(
    async ({ limit, offset, searchQuery }: FetchPaymentsParams) => {
      if (!partnerId) {
        return
      }

      setIsLoading(true)

      try {
        const params: any = {
          partnerId,
          limit,
          offset,
        }

        if (searchQuery) {
          params.search = searchQuery
        }

        if (selectedMarket?.id) {
          params.partnerMarketId = selectedMarket?.id
        }

        const payload: Array<Payment> = await createAsyncAction(
          dispatch,
          FetchPayments.request(params),
        )

        setPayments(payload)
        setTotalPayments(payload.length)
      } catch (err: unknown) {
        handleApiError(err)
      }

      setIsLoading(false)
    },
    [partnerId, selectedMarket?.id],
  )

  const refreshPayments = useCallback(async () => {
    await fetchPayments({ limit, offset, searchQuery })
  }, [limit, offset, searchQuery, fetchPayments])

  useEffect(() => {
    fetchPayments({
      limit,
      offset,
      searchQuery,
    })
  }, [limit, offset, searchQuery, partnerId, selectedMarket?.id])

  const handleChangePagination = useCallback((paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)
  }, [])

  const openPaymentsItemAction = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, paymentToEdit: Payment) => {
      setActionAnchor(event.currentTarget)

      setPaymentToAction(paymentToEdit)
    },
    [],
  )

  const closePaymentsItemAction = useCallback(() => {
    setActionAnchor(null)
  }, [])

  const clearPaymentsItemAction = useCallback(() => {
    setActionAnchor(null)
    setPaymentToAction(null)
  }, [])

  const handleSelectStatus = useCallback((statuses: Array<PaymentStatus>) => {
    setStatusFilter(statuses)
    setSelectedRows([])
  }, [])

  const paymentsContextValue = useMemo<IPaymentsContext>(() => {
    return {
      selectedRows,
      setSelectedRows,

      selectedPayments,

      searchQuery,
      setSearchQuery,

      statusFilter,
      setStatusFilter: handleSelectStatus,

      refreshPayments,

      actionAnchor,
      paymentToAction,
      openPaymentsItemAction,
      closePaymentsItemAction,
      clearPaymentsItemAction,
    }
  }, [
    selectedRows,
    setSelectedRows,

    selectedPayments,

    searchQuery,
    setSearchQuery,

    statusFilter,
    handleSelectStatus,

    refreshPayments,

    actionAnchor,
    paymentToAction,
    openPaymentsItemAction,
    closePaymentsItemAction,
    clearPaymentsItemAction,
  ])

  return (
    <PaymentsContext.Provider value={paymentsContextValue}>
      <div css={tw`flex flex-row items-center justify-between mb-6`}>
        <Typography variant="h2" css={tw`mb-2`}>
          Payments
        </Typography>
        <div>
          <a href="/payments_example.csv" download="payments_example.csv">
            <Button
              className={tw`mr-4`}
              size="small"
              color="white"
              rightIcon={<NoteMinor width={20} height={20} />}
            >
              Download CSV template
            </Button>
          </a>
          <ProtectedWithPermission permission={UserPermission.PaymentsCreate}>
            <UploadPaymentsCSVButton />
          </ProtectedWithPermission>
          <ProtectedWithPermission permission={UserPermission.PaymentsProcess}>
            <ProcessPaymentsButton notPaidPaymentsAmount={notPaidPayments.length} />
          </ProtectedWithPermission>
        </div>
      </div>
      <PaymentsHeader />
      <PaymentsGrid
        payments={filteredPayments}
        isLoading={isLoading}
        paginationModel={paginationModel}
        pageSizeOptions={paymentPageSizeOptions}
        rowCount={totalPayments}
        rowSelectionModel={selectedRows}
        onPaginationModelChange={handleChangePagination}
        onRowSelectionModelChange={setSelectedRows}
      />
      <PaymentsItemActionMenu />
    </PaymentsContext.Provider>
  )
}

export const Payments = memo(PaymentsComponent)
