import {
  BlogMajor,
  ChecklistAlternateMajor,
  CustomersMajor,
  FinancesMajor,
  NoteMajor,
  PageDownMajor,
  QuestionMarkMajor,
  SecureMajor,
  ShipmentMajor,
  SmileyHappyMajor,
  TextBlockMajor,
} from '@shopify/polaris-icons'
import DriverIcon from 'src/assets/icons/driver-front.svg'
import BGCIcon from 'src/assets/icons/bgc.svg'
import BookmarkIcon from 'src/assets/icons/bookmark.svg'

export enum CollectedDataStatus {
  Created = 'created',
  InProgress = 'in_progress',
  WaitingForInternalApproval = 'waiting_for_internal_approval',
  WaitingForApproval = 'waiting_for_approval',
  Completed = 'completed',
  Failed = 'failed', // Applies only for BG check
}

export enum CollectedDataStepType {
  DriverLicense = 'driver_license',
  VehicleAndInsurance = 'vehicle_and_insurance',
  InsuranceAmountDeclaration = 'insurance_amount_declaration',
  VehicleRegistration = 'vehicle_registration',
  VaccinationInformation = 'vaccination_information',
  BackgroundCheck = 'background_check',
  PaymentMethod = 'payment_method',
  InsuranceBeneficiary = 'insurance_beneficiary',
  SignContract = 'sign_contract',
  Training = 'training',
  StitchPayment = 'stitch_payment',
  Questionnaire = 'questionnaire',
  MvrUpload = 'mvr_upload',
  InPersonMeeting = 'in_person_meeting',
  Form = 'form',
  BusinessEntity = 'business_entity',
}

export const collectedDataStepsTitle = {
  [CollectedDataStepType.Questionnaire]: 'Questionnaire',
  [CollectedDataStepType.Form]: 'Questionnaire',
  [CollectedDataStepType.DriverLicense]: "Driver's license",
  [CollectedDataStepType.VehicleAndInsurance]: 'Vehicle and insurance',
  [CollectedDataStepType.InsuranceAmountDeclaration]: 'Insurance amount declaration',
  [CollectedDataStepType.VehicleRegistration]: 'Vehicle registration',
  [CollectedDataStepType.VaccinationInformation]: 'Vaccination information',
  [CollectedDataStepType.BackgroundCheck]: 'Background check',
  [CollectedDataStepType.PaymentMethod]: 'Payment method',
  [CollectedDataStepType.StitchPayment]: 'Payment method',
  [CollectedDataStepType.InsuranceBeneficiary]: 'Insurance beneficiary',
  [CollectedDataStepType.SignContract]: 'Sign the contract',
  [CollectedDataStepType.Training]: 'Training',
  [CollectedDataStepType.MvrUpload]: 'MVR Upload',
  [CollectedDataStepType.InPersonMeeting]: 'In-person meeting',
  [CollectedDataStepType.BusinessEntity]: 'Business Entity',
}

export const collectedDataStepIconsMap = {
  [CollectedDataStepType.Questionnaire]: <QuestionMarkMajor width={20} />,
  [CollectedDataStepType.Form]: <ChecklistAlternateMajor width={20} />,
  [CollectedDataStepType.DriverLicense]: <img src={DriverIcon} />,
  [CollectedDataStepType.VehicleAndInsurance]: <ShipmentMajor width={20} />,
  [CollectedDataStepType.InsuranceAmountDeclaration]: <NoteMajor height={20} />,
  [CollectedDataStepType.VehicleRegistration]: <TextBlockMajor height={20} width={20} />,
  [CollectedDataStepType.VaccinationInformation]: <SecureMajor height={20} width={20} />,
  [CollectedDataStepType.BackgroundCheck]: <img src={BGCIcon} />,
  [CollectedDataStepType.PaymentMethod]: <FinancesMajor height={20} width={20} />,
  [CollectedDataStepType.StitchPayment]: <FinancesMajor height={20} width={20} />,
  [CollectedDataStepType.InsuranceBeneficiary]: <CustomersMajor height={18} width={16} />,
  [CollectedDataStepType.SignContract]: <BlogMajor height={20} width={19} />,
  [CollectedDataStepType.Training]: <img src={BookmarkIcon} />,
  [CollectedDataStepType.MvrUpload]: <PageDownMajor width={20} />,
  [CollectedDataStepType.InPersonMeeting]: <SmileyHappyMajor height={20} width={20} />,
  [CollectedDataStepType.BusinessEntity]: <NoteMajor height={20} width={20} />,
}

export enum CollectDataFormItemType {
  TextBlock = 'textBlock',
  TextInput = 'textInput',
  Date = 'date',
  Number = 'number',
  Select = 'select',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Video = 'video',
  File = 'file',
}
