export enum Endpoints {
  FetchInvite = '/v1/gigsafe/auth/invite/:inviteId',
  SignUp = '/v1/gigsafe/auth/sign-up',
  SignIn = '/v1/gigsafe/auth/sign-in',
  RefreshToken = '/v1/gigsafe/auth/refresh-token',
  ForgotPassword = '/v1/gigsafe/auth/forgot-password',
  ResetPassword = '/v1/gigsafe/auth/reset-password',

  FetchUser = '/v1/gigsafe/users/self',

  FetchMarkets = '/v1/gigsafe/onboarding/dashboard/partner-markets',
  FetchOnboardingTemplates = '/v1/gigsafe/onboarding/dashboard/onboarding-templates',

  FetchOnboardingsCounts = '/v2/gigsafe/onboarding/dashboard/partner-drivers-onboardings-counts',
  FetchOnboardings = '/v2/gigsafe/onboarding/dashboard/partner-drivers-onboardings',
  FetchOnboarding = '/v1/gigsafe/onboarding/dashboard/partner-drivers-onboardings/:onboardingId',
  FetchOnboardingDocuments = '/v1/gigsafe/onboarding/dashboard/onboarding-documents/:onboardingId',
  FetchOnboardingContracts = '/v1/gigsafe/onboarding/dashboard/onboarding-documents/:onboardingId/contracts',
  FetchRecollectingSteps = '/v1/gigsafe/document-recollection/steps/:partnerDriverId',
  SendRemainderOnboarding = '/v1/gigsafe/onboarding/dashboard/:partnerDriverId/send-reminder',

  FetchPayments = '/v1/gigsafe/payments/dashboard',
  UploadPaymentsCSV = '/v1/gigsafe/payments/dashboard/upload-earnings-csv',
  FetchPaymentInvoice = '/v1/gigsafe/payments/dashboard/invoice/:paymentId',
  DeletePayment = '/v1/gigsafe/payments/dashboard/earnings ',
  ProcessPayments = '/v1/gigsafe/payments/dashboard/process-payments',

  FetchContractors = '/v1/gigsafe/dashboards/contractors',
  FetchContractorsCount = '/v1/gigsafe/dashboards/contractors/counts',
  FetchContractor = '/v1/gigsafe/dashboards/contractors/:contractorId',
  SendRemainderContractor = '/v1/gigsafe/dashboards/contractors/:partnerDriverId/send-reminder',

  UploadPartnerDriversCSV = '/v1/gigsafe/onboarding/dashboard/bulk-invite',
  CreatePartnerDriver = '/v1/gigsafe/onboarding/dashboard/driver',
  UpdatePartnerDriver = '/v1/gigsafe/onboarding/dashboard/driver/:partnerDriverId',
  ActivatePartnerDriver = '/v1/gigsafe/onboarding/dashboard/driver/:partnerDriverId/activate',
  DeactivatePartnerDriver = '/v1/gigsafe/onboarding/dashboard/driver/:partnerDriverId/deactivate',

  FetchTags = '/v1/gigsafe/tags',
  CreateTag = '/v1/gigsafe/tags',
  DeleteTag = '/v1/gigsafe/tags/:tagId',
  DriverAddTag = '/v1/gigsafe/tags/:tagId/driver',
  DriverDeleteTag = '/v1/gigsafe/tags/:tagId/driver',

  RejectOnboarding = '/v1/gigsafe/onboarding/dashboard/reject-onboarding',
  UnrejectOnboarding = '/v1/gigsafe/onboarding/dashboard/reset-failed-steps',

  ApproveOnboardingStep = '/v1/gigsafe/onboarding/dashboard/approve-step',
  RecollectOnboardingStep = '/v1/gigsafe/onboarding/dashboard/recollect-step',
}
