import { useCallback } from 'react'
import {
  FetchMarkets,
  FetchOnboardingTemplates,
  FetchTags,
  FetchUser,
} from 'src/constants/actionTypes'
import { useAsyncDispatchWithErrorToast } from './useAsyncDispatchWithErrorToast'
import { User } from 'src/interfaces/user'

export const useFetchUserAndData = () => {
  const { asyncDispatchWithErrorToast } = useAsyncDispatchWithErrorToast()

  const fetchUserAndMarkets = useCallback(async () => {
    const user = await asyncDispatchWithErrorToast<User | null>(FetchUser.request())

    if (!user) {
      return
    }

    asyncDispatchWithErrorToast(FetchMarkets.request({ partnerId: user.partner.id }))
    asyncDispatchWithErrorToast(FetchTags.request())
    asyncDispatchWithErrorToast(FetchOnboardingTemplates.request({ partnerId: user.partner.id }))
  }, [])

  return { fetchUserAndMarkets }
}
