import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react'
import { Modal, Typography } from '@mui/material'
import tw from 'twin.macro'
import { ModalHeader } from 'src/components/shared/ModalHeader'
import { ContractorDetails } from 'src/interfaces/contractor'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { useDispatch } from 'react-redux'
import {
  ActivatePartnerDriver,
  DeactivatePartnerDriver,
  RejectOnboarding,
  UnrejectOnboarding,
  SendRemainderContractor,
} from 'src/constants/actionTypes'
import { ContractorOnboardingStatus } from 'src/constants/contractor/enums'
import { ContractorPendingStep } from './ContractorPendingStep'
import { ContractorFailedStep } from './ContractorFailedStep'
import { ContractorPartnerDriverInfo } from './ContractorPartnerDriverInfo'
import { RejectContractor as RejectContractorComponent } from './RejectContractor'
import { showToast } from 'src/utils/toast'
import { ActivateContractorButton } from './ActivateContractorButton'
import { DeactivateContractorButton } from './DeactivateContractorButton'
import { handleApiError } from 'src/utils/errorHandlers'
import { clientSortCollectedData } from 'src/utils/collectedData'
import { ContractorSteps } from './ContractorSteps'
import { UnrejectContractor as UnrejectContractorComponent } from './UnrejectContractor'
import { ClockMajor, SendMajor } from '@shopify/polaris-icons'
import { Button } from '../shared/Button'
import { colors } from 'src/theme'
import { CollectedDataStatus } from 'src/constants/collectedData'

interface Props {
  children?: ReactNode
  contractorDetails: ContractorDetails
  showWaitingForApprovalStepsFirst?: boolean
  flow: 'onboardings' | 'contractors'
  onClose(): void
  onRefresh(): Promise<void>
}

const ContractorDetailsModalComponent = ({
  contractorDetails,
  flow,
  showWaitingForApprovalStepsFirst,
  onClose,
  onRefresh,
}: Props) => {
  const dispatch = useDispatch()

  const container = useMemo(() => document.getElementById('root'), [])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const processedContractorDetails = useMemo(
    () => ({
      ...contractorDetails,
      collectedData: clientSortCollectedData(
        contractorDetails.collectedData,
        showWaitingForApprovalStepsFirst,
      ),
    }),
    [contractorDetails, showWaitingForApprovalStepsFirst],
  )

  const handleRejectWorker = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        RejectOnboarding.request({ onboardingId: processedContractorDetails.onboarding.id }),
      )
      showToast('Contractor rejected!')
      onRefresh()
    } catch (err) {
      handleApiError(err)
    }
  }, [processedContractorDetails, onRefresh])

  const handleUnrejectWorker = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        UnrejectOnboarding.request({ onboardingId: processedContractorDetails.onboarding.id }),
      )
      showToast('Contractor unrejected!')
      await onRefresh()
      onClose()
    } catch (err) {
      handleApiError(err)
    }
  }, [processedContractorDetails, onRefresh])

  const handleActivateWorker = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        ActivatePartnerDriver.request({
          partnerDriverId: processedContractorDetails.id,
        }),
      )
      showToast('Contractor activated!')
      onRefresh()
    } catch (err) {
      handleApiError(err)
    }
  }, [processedContractorDetails, onRefresh])

  const handleDeactivateWorker = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        DeactivatePartnerDriver.request({
          partnerDriverId: processedContractorDetails.id,
        }),
      )
      showToast('Contractor deactivated!')
      onRefresh()
    } catch (err) {
      handleApiError(err)
    }
  }, [processedContractorDetails, onRefresh])

  const flowButton = useMemo(() => {
    if (processedContractorDetails.onboarding.status === ContractorOnboardingStatus.Failed) {
      return <UnrejectContractorComponent onUnreject={handleUnrejectWorker} />
    }

    if (flow === 'onboardings') {
      if (processedContractorDetails.onboarding.status === ContractorOnboardingStatus.Completed) {
        return null
      }

      return <RejectContractorComponent onReject={handleRejectWorker} />
    }

    if (processedContractorDetails.isActive) {
      return <DeactivateContractorButton onDeactivate={handleDeactivateWorker} />
    } else {
      return <ActivateContractorButton onActivate={handleActivateWorker} />
    }
  }, [flow, handleRejectWorker, handleActivateWorker, handleDeactivateWorker])

  const hasStepsWithRecollectingStatus = useMemo(
    () => contractorDetails.collectedData.some((item) => !!item.isRecollecting),
    [contractorDetails],
  )

  const handleResendInvite = useCallback(async () => {
    setIsLoading(true)

    try {
      await createAsyncAction(
        dispatch,
        SendRemainderContractor.request({
          partnerDriverId: contractorDetails.id,
        }),
      )

      showToast("The reminder has been resent to the contractor's email address and phone number!")
    } catch (err) {
      handleApiError(err)
    }

    setIsLoading(false)
  }, [])

  return (
    <Modal
      container={container}
      open
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center backdrop-blur flex-1 py-8`}
    >
      <div css={tw`bg-white rounded-lg overflow-hidden max-h-full w-[724px] flex flex-col`}>
        <ModalHeader title="Driver's details" onClose={onClose} />
        <div css={tw`flex bg-[#F6F6F6] overflow-scroll`}>
          <div css={tw`flex flex-1 flex-col justify-between`}>
            <ContractorPartnerDriverInfo
              contractorDetails={processedContractorDetails}
              status={processedContractorDetails.onboarding.status}
              onUpdate={onRefresh}
            />
            {flowButton}
          </div>
          <div css={tw`bg-[#EDEDED] flex w-[1px]`} />
          <div css={tw`flex flex-1 flex-col p-4 overflow-y-scroll`}>
            {(processedContractorDetails.onboarding.status === ContractorOnboardingStatus.Created ||
              processedContractorDetails.onboarding.status ===
                ContractorOnboardingStatus.PartiallyCompleted) && (
              <div>
                <ContractorPendingStep contractorDetails={processedContractorDetails} />
                <ContractorSteps
                  contractorDetails={processedContractorDetails}
                  onRefresh={onRefresh}
                  collapsible
                />
              </div>
            )}
            {(!processedContractorDetails.onboarding.status ||
              processedContractorDetails.onboarding.status ===
                ContractorOnboardingStatus.WaitingForApproval ||
              processedContractorDetails.onboarding.status ===
                ContractorOnboardingStatus.Completed) && (
              <div>
                {hasStepsWithRecollectingStatus && flow === 'contractors' ? (
                  <div css={tw`rounded-lg bg-white overflow-hidden mb-4`}>
                    <div
                      css={tw`flex items-center px-4 py-3 border-0 border-b border-solid border-[#EDEDED]`}
                    >
                      <ClockMajor css={tw`mr-3`} width={20} height={20} />
                      <Typography variant="subtitle1">Recollection pending</Typography>
                    </div>
                    <Button
                      rightIcon={
                        <SendMajor fill={colors.PRIMARY_PURPLE_500} width={20} height={20} />
                      }
                      className={tw`mx-4 my-3`}
                      color="light"
                      size="small"
                      onClick={handleResendInvite}
                      loading={isLoading}
                    >
                      Resend an invite
                    </Button>
                  </div>
                ) : null}
                <ContractorSteps
                  contractorDetails={processedContractorDetails}
                  onRefresh={onRefresh}
                />
              </div>
            )}
            {processedContractorDetails.onboarding.status === ContractorOnboardingStatus.Failed && (
              <div>
                <ContractorFailedStep contractorDetails={processedContractorDetails} />
                <ContractorSteps
                  contractorDetails={processedContractorDetails}
                  filterByStatus={CollectedDataStatus.Failed}
                  onRefresh={onRefresh}
                  collapsible
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const ContractorDetailsModal = memo(ContractorDetailsModalComponent)
