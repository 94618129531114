import { Modal, Typography } from '@mui/material'
import { memo, useCallback, useMemo } from 'react'
import tw from 'twin.macro'
import { ModalHeader } from '../shared/ModalHeader'
import { useDispatch, useSelector } from 'react-redux'
import { getActivityDetails } from 'src/selectors/activities'
import { SetActivityDetails } from 'src/constants/actionTypes'
import { colors } from 'src/theme'

const ActivitiesDetailsModalComponent = () => {
  const dispatch = useDispatch()

  const activityDetails = useSelector(getActivityDetails)

  const container = useMemo(() => document.getElementById('root'), [])

  const handleClose = useCallback(() => {
    dispatch(SetActivityDetails.success(null))
  }, [])

  return (
    <Modal
      container={container}
      open={Boolean(activityDetails)}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center flex-1 py-8`}
    >
      {activityDetails ? (
        <div css={tw`bg-white rounded-lg overflow-hidden max-h-full w-[600px] flex flex-col`}>
          <ModalHeader title={activityDetails.action} onClose={handleClose} />
          <div css={tw`flex flex-col bg-[#FFFFFF] overflow-auto p-3`}>
            <Typography css={tw`mb-3`} variant="h6" fontWeight={600} color={colors.SHADES_GREY_400}>
              {activityDetails.title}
            </Typography>
            <div css={tw`flex flex-col`}>
              {activityDetails.content.map((contentItem, idx) => (
                <div key={idx} css={tw`mb-3 flex`}>
                  <div css={tw`mr-1`}>
                    <Typography variant="body2" color={colors.SHADES_GREY_400}>{`${
                      idx + 1
                    }.`}</Typography>
                  </div>
                  <div css={tw`flex-1`}>
                    <Typography variant="body2" color={colors.SHADES_GREY_400}>
                      {contentItem}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </Modal>
  )
}

export const ActivitiesDetailsModal = memo(ActivitiesDetailsModalComponent)
