import { ReactNode, useCallback } from 'react'
import { Button } from './Button'

interface Props {
  icon: ReactNode
  label?: string
  route: string
  selected?: boolean
  className?: any
  onClick(route: string): void
}

export const SideMenuButton = ({ icon, label, route, selected, className, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick(route)
  }, [route, onClick])

  return (
    <Button
      leftIcon={icon}
      color={selected ? 'white' : 'grey'}
      className={className}
      size="small"
      onClick={handleClick}
    >
      {label}
    </Button>
  )
}
